<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="text-primary mb-0">Support Tickets</h3>
      <div>
        <router-link :to="{ name: 'new-support' }">
          <button
            class="btn btn-sm btn-success font-weight-bold text-uppercase"
          >
            + Create New Ticket
          </button>
        </router-link>
      </div>
    </div>

    <div class="separator-breadcrumb border-top"></div>

    <div class="card">
      <div class="card-body">
        <ul
          class="nav nav-tabs nav-justified mb-4 font-weight-bold text-uppercase"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-icon-pill"
              data-toggle="pill"
              @click="getTickets()"
              role="tab"
              aria-controls="homePIll"
              aria-selected="true"
            >
              <i class="nav-icon i-Settings-Window mr-1"></i> All
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-icon-pill"
              data-toggle="pill"
              @click="getTickets('Kiosk')"
              role="tab"
              aria-controls="profilePIll"
              aria-selected="false"
              ><i class="nav-icon i-Bird-Delivering-Letter mr-1"></i> Kiosk
              Department</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-icon-pill"
              data-toggle="pill"
              @click="getTickets('Router')"
              role="tab"
              aria-controls="contactPIll"
              aria-selected="false"
            >
              <i class="nav-icon i-Home1 mr-1"></i> Router Department
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="pill"
              @click="getTickets('Other')"
              role="tab"
              aria-controls="contactPIll"
              aria-selected="false"
            >
              <i class="nav-icon i-Home1 mr-1"></i> Other
            </a>
          </li>
        </ul>
        <DataTable :columns="columns" :rows="tickets" />
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      columns: [
        { field: "id", label: "ID" },
        { field: "message", label: "Message" },
        { field: "created", label: "created", formatFn: this.formatDateTime },
        { field: "is_open", label: "Status", sortable: false },
        { field: "action", label: "Action", sortable: false }
      ],
      tickets: []
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"])
  },
  mounted() {
    this.getTickets();
  },
  methods: {
    formatDateTime(value) {
      return this.$moment(value).format("dddd, MMMM Do YYYY, h:mm a");
    },
    async getTickets(department) {
      let ticketUrl = `v1/tickets/`;
      if (this.loggedInUser.partner) {
        ticketUrl = `v1/tickets/?partner=${this.loggedInUser.partner.id}`;
      }
      if (department) {
        ticketUrl = `v1/tickets/?department=${department}`;
      }
      try {
        await axios.get(ticketUrl).then(response => {
          if (response.status === 200) {
            this.tickets = response.data;
          }
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped>
.nav-item {
  cursor: pointer !important;
}
</style>
